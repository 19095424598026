import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import VideoBanner from "../components/VideoBanner"
// import vid2 from "../assets/videos/vid2.mp4"

const WhatWeDo = ({ data }) => {
  const { strapiWhatWeDoPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="What We Do" />
      <VideoBanner
        heading={info.Banner_heading}
        para={info.Banner_description}
        vid={info.Banner_video.url}
      />
      <Container className="my-5">
        <h1 className="my-3">{info.info_heading}</h1>
        <p className="my-4 h4">{info.info_description}</p>
      </Container>
    </Layout>
  )
}

export const data = graphql`
  {
    strapiWhatWeDoPage {
      Banner_video {
        url
      }
      Banner_heading
      Banner_description
      info_heading
      info_description
    }
  }
`

export default WhatWeDo
